<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { postJson, ServerError } from "@ui/http";
  import { validateEmail, validatePassword } from "./validations";
  import GoogleButton from "./GoogleButton.svelte";
  import FacebookButton from "./FacebookButton.svelte";
  import { getAuthenticationContext } from "./context";
  import type { LoginResult } from "./types";
  import Recaptcha from "./Recaptcha.svelte";
  import { fade } from "svelte/transition";

  export let errorCode: string | undefined = undefined;
  export let email: string = "";
  export let showReCaptcha: boolean;

  const dispatcher = createEventDispatcher();
  const context = getAuthenticationContext();

  let password: string = "";
  let showPassword: boolean = false;
  let submitting: boolean = false;

  let emailInput: HTMLInputElement;

  let recaptchaToken: string | undefined;
  let recaptchaComponent: Recaptcha;

  $: [emailValid, emailValidationMessage] = validateEmail(email, "login");
  $: passwordValid = validatePassword(password);
  $: canSubmit = emailValid && passwordValid && !submitting && (showReCaptcha ? recaptchaToken : true);

  async function submitClicked() {
    resetError();
    submitting = false;
    if (!canSubmit) {
      return;
    }

    try {
      submitting = true;
      const result = await postJson<LoginResult>("/app/auth/do_login_json", {
        email,
        password,
        "g-recaptcha-response": recaptchaToken
      });

      if (result.require2FA) {
        dispatcher("require2FA", result.redirectUrl);
      } else {
        dispatcher("login", result.redirectUrl);
      }
    } catch (e) {
      if (showReCaptcha && recaptchaComponent) {
        recaptchaComponent.reset();
      }
      const ex: ServerError = e as ServerError;
      if (ex.code === "trysaml" && ex.extraData?.redirect_url) {
        document.body.innerHTML = "";
        document.location.href = ex.extraData?.redirect_url;
      }
      errorCode = ex.code || "generalerror";

      if (Array.isArray(errorCode)) {
        window.requestAnimationFrame(() => {
          //@ts-ignore
          const element: HTMLElement = document.querySelector(`[name=${errorCode[0][0]}]`);
          if (element) {
            //@ts-ignore
            if (element.select) {
              //@ts-ignore
              element.select();
            }
            element.focus();
          }
        });
      }
    } finally {
      submitting = false;
    }
  }

  function resetError() {
    errorCode = undefined;
  }

  function forgetPasswordClicked() {
    resetError();
    dispatcher("modeChanged", "forgot-password");
  }

  onMount(() => {
    if (emailInput) {
      emailInput.focus();
    }
  });

  function showPasswordClicked(evt: MouseEvent) {
    showPassword = true;
  }

  function hidePasswordClicked(evt: MouseEvent) {
    showPassword = false;
  }

  // let hideForm = true;
</script>

<h2 class="mb-4 font-bold text-nxgray-600 text-18">Log in to your account</h2>
<GoogleButton mode="login" />
<div class="mt-4">
  <FacebookButton />
</div>
<div class="flex items-center justify-center my-3">
  <hr class="flex-grow" />
  <span class="px-2 bg-white text-13 text-nxgray-300">or</span>
  <hr class="flex-grow" />
</div>

<!-- <div
  on:click={() => (hideForm = false)}
  class:hidden={!hideForm}
  class="w-full p-2 text-center transition-all border-2 border-gray-300 rounded-md text-14 clickable hover:bg-gray-100">
  Continue with email and password
</div> -->
<form class="flex flex-col transition-all" on:submit|preventDefault|stopPropagation={submitClicked}>
  <label class="mb-1 text-nxgray-400 text-13" for="email">Email address</label>
  <input
    class="text-14"
    class:valid={emailValid}
    type="text"
    name="email"
    autocomplete="email"
    bind:this={emailInput}
    bind:value={email}
    placeholder="name@email.com" />
  {#if emailValidationMessage}
    <div class="text-red-500 text-13" class:visible={emailValidationMessage} class:invisible={!emailValidationMessage} transition:fade>
      {emailValidationMessage}
    </div>
  {/if}

  <div class="flex items-center justify-between mt-2">
    <label class="mb-1 text-nxgray-400 text-13" for="password">Password</label>
    {#if !$context.hideForgotPassword}
      <button
        tabindex="-1"
        on:click|preventDefault={forgetPasswordClicked}
        class="px-2 -mx-2 font-medium text-teal-600 outline-none focus:outline-none clickable active:outline-none text-13"
        >I forgot my password</button>
    {/if}
  </div>
  {#if showPassword}
    <div class="relative">
      <input
        class="w-full text-14"
        class:valid={passwordValid}
        type="text"
        name="password"
        bind:value={password}
        placeholder="Enter your password" />
      <label class="absolute w-4 right-4 top-1/4 text-nxgray-400 clickable" for="password" on:click|stopPropagation={hidePasswordClicked}>
        <span class="pointer-events-none material-icons text-18"> visibility_off </span>
      </label>
    </div>
  {:else}
    <div class="relative w-full">
      <input
        class="w-full pr-10 text-14"
        class:valid={passwordValid}
        type="password"
        name="password"
        bind:value={password}
        placeholder="Enter your password" />
      <label class="absolute w-4 right-4 top-1/4 text-nxgray-400 clickable" for="password" on:click|stopPropagation={showPasswordClicked}>
        <span class="pointer-events-none material-icons text-18"> visibility </span>
      </label>
    </div>
  {/if}

  {#if showReCaptcha && (email || password)}
    <div transition:fade>
      <Recaptcha bind:token={recaptchaToken} bind:this={recaptchaComponent} />
    </div>
  {/if}
  <input
    type="submit"
    on:click|preventDefault|stopPropagation={submitClicked}
    disabled={!canSubmit}
    class="w-full py-3 mt-2 font-bold text-center text-white bg-gray-200 rounded pointer-events-none select-none hover:bg-gray-100 focus:bg-gray-100 text-15 g-recaptcha"
    class:pointer-events-auto={canSubmit}
    class:bg-orange-600={canSubmit}
    class:clickable={canSubmit}
    class:hover:bg-orange-500={canSubmit}
    class:focus:bg-orange-500={canSubmit}
    value="Log in to Smore" />
</form>

<style>
  input[name="password"] {
    padding-right: 2rem !important;
  }

  input:not([type="submit"]) {
    padding-left: theme("padding.3");
    padding-right: theme("padding.3");
    padding-top: theme("padding.2");
    padding-bottom: theme("padding.2");
    border-radius: theme("borderRadius.DEFAULT");
    border-width: theme("borderWidth.DEFAULT");
    color: theme("textColor.nxgray.600");
    outline-offset: theme("outlineOffset.2");
    outline-width: theme("outlineWidth.2");
    outline-color: theme("outlineColor.transparent");
    outline-style: solid;
  }

  input:not([type="submit"]):not(.valid):not(:placeholder-shown):focus,
  input:not([type="submit"]):not(.valid):not(:placeholder-shown) {
    border-color: theme("borderColor.red.500");
  }

  input:not([type="submit"]).valid:focus,
  input:not([type="submit"]).valid {
    border-color: theme("borderColor.green.500");
  }
</style>
