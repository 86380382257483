<script lang="ts">
  import { postJson, ServerError } from "@ui/http";
  import { validatePassword } from "./validations";
  import { fade } from "svelte/transition";

  export let token: string;
  export let email: string;

  let newPassword: string = "";
  let newConfirmationPassword: string = "";
  let showErrorMessage: boolean = false;
  let updateErrorMessage: string | null | undefined;
  let isSubmitting: boolean = false;

  const codeToMessage: { [code: string]: string } = {
    password_compromised: `Your password is too common.<br/><br/>Please create a unique password with an uppercase letter, a symbol and a number to continue.`
  };

  $: isPasswordValid = validatePassword(newPassword);
  $: isConfirmationPasswordValid = newPassword == newConfirmationPassword && validatePassword(newConfirmationPassword);
  $: canSubmitResetPasswordForm = isPasswordValid && isConfirmationPasswordValid;

  async function submitForm() {
    isSubmitting = true;
    const data = {
      new_password: newPassword,
      token: token
    };

    try {
      showErrorMessage = false;
      updateErrorMessage = null;
      const result = await postJson<{ redirectUrl: string }>("/app/auth/do_create_fb_password", data);
      document.location.href = result.redirectUrl;
    } catch (ex: any) {
      updateErrorMessage = codeToMessage[ex.code] || ServerError.GENERIC_ERROR;
      showErrorMessage = true;
      isSubmitting = false;
    }
  }
</script>

{#if showErrorMessage}
  <div transition:fade class="px-4 py-2 mb-4 font-bold text-white bg-red-500 rounded">
    {@html updateErrorMessage}
  </div>
{/if}
<h2 class="font-bold text-nxgray-600 text-18">Create your password</h2>
<h3 class="mt-1 text-nxgray-600 text-13">
  To log into Smore using your email {email}, you will need to create a password below.
</h3>
<form class="w-full mt-4">
  <input type="hidden" name="token" value={token} />

  <label class="inline-block w-full mb-1 text-nxgray-400 text-13">
    Enter password
    <input class="w-full text-14" class:valid={isPasswordValid} type="password" name="password" bind:value={newPassword} />
  </label>
  <label class="inline-block w-full mt-4 mb-1 text-nxgray-400 text-13"
    >Verify password
    <input
      class="w-full text-14"
      class:valid={isConfirmationPasswordValid}
      type="password"
      name="confirmationPassword"
      bind:value={newConfirmationPassword} />
  </label>

  <button
    on:click|stopPropagation|preventDefault={submitForm}
    disabled={isSubmitting || !canSubmitResetPasswordForm}
    class="w-full py-3 mt-4 font-bold text-center text-white bg-gray-200 rounded pointer-events-none select-none hover:bg-gray-100 focus:bg-gray-100 text-15"
    class:pointer-events-auto={!isSubmitting && canSubmitResetPasswordForm}
    class:clickable={!isSubmitting && canSubmitResetPasswordForm}
    class:bg-orange-600={!isSubmitting && canSubmitResetPasswordForm}
    class:hover:bg-orange-500={!isSubmitting && canSubmitResetPasswordForm}
    class:focus:bg-orange-500={!isSubmitting && canSubmitResetPasswordForm}
    >{isSubmitting ? "Resetting Password" : "Reset Password"}</button>
</form>

<style>
  input:not([type="submit"]) {
    padding-left: theme("padding.3");
    padding-right: theme("padding.3");
    padding-top: theme("padding.2");
    padding-bottom: theme("padding.2");
    border-radius: theme("borderRadius.DEFAULT");
    border-width: theme("borderWidth.DEFAULT");
    color: theme("textColor.nxgray.600");
    outline-offset: theme("outlineOffset.2");
    outline-width: theme("outlineWidth.2");
    outline-color: theme("outlineColor.transparent");
    outline-style: solid;
  }

  input:not([type="submit"]):not(.valid):not(:placeholder-shown):focus,
  input:not([type="submit"]):not(.valid):not(:placeholder-shown) {
    border-color: theme("borderColor.red.500");
  }

  input:not([type="submit"]).valid:focus,
  input:not([type="submit"]).valid {
    border-color: theme("borderColor.green.500");
  }
</style>
