<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { postJson, ServerError } from "@ui/http";
  import { validateEmail, validateFirstName, validateLastName, validatePassword } from "./validations";
  import GoogleButton from "./GoogleButton.svelte";
  import type { SignupResult } from "./types";
  import { getAuthenticationContext } from "./context";
  import Recaptcha from "./Recaptcha.svelte";
  import { fade } from "svelte/transition";
  import AcceptTermsModal from "./AcceptTermsModal.svelte";

  export let errorCode: string | undefined = undefined;
  export let email: string = "";
  export let showReCaptcha: boolean;
  export let showTermsAndContinueWithGoogle = false;

  const dispatcher = createEventDispatcher();
  const context = getAuthenticationContext();

  let password: string = "";
  let showPassword: boolean = false;
  let submitting: boolean = false;

  let fnameInput: HTMLInputElement;

  let recaptchaToken: string | undefined;
  let recaptchaComponent: Recaptcha;

  let showAcceptTermsModal = showTermsAndContinueWithGoogle;
  let createNewAccountUsing: "email" | "google";

  let googleButtonElement: GoogleButton;

  $: isOrgInvite = ($context.inviteToken || "").length > 0 && !$context.isMagicInvite;
  $: [emailValid, emailValidationMessage] = isOrgInvite ? [true, undefined] : validateEmail(email, "signup");
  $: [fnameValid, fnameValidationMessage] = validateFirstName($context.firstName);
  $: [lnameValid, lnameValidationMessage] = validateLastName($context.lastName);
  $: passwordValid = validatePassword(password);

  $: canSubmit = emailValid && passwordValid && !submitting && fnameValid && lnameValid && (showReCaptcha ? recaptchaToken : true);

  async function submitClicked() {
    createNewAccountUsing = "email";
    showAcceptTermsModal = true;
  }

  async function clickedContinueWithGoogle() {
    createNewAccountUsing = "google";
    showAcceptTermsModal = true;
  }

  async function doSignup() {
    if (createNewAccountUsing === "google" || showTermsAndContinueWithGoogle) {
      googleButtonElement.continueWithGoogle();
      return;
    }

    submitting = false;
    resetError();
    try {
      submitting = true;
      const result = await postJson<SignupResult>("/app/auth/do_signup_json", {
        email,
        password,
        "first_name": $context.firstName,
        "last_name": $context.lastName,
        "g-recaptcha-response": recaptchaToken,
        "invite_token": $context.inviteToken
      });

      dispatcher("signup", result);
    } catch (e) {
      if (showReCaptcha && recaptchaComponent) {
        recaptchaComponent.reset();
      }
      const ex: ServerError = e as ServerError;

      // errorCode is binded from the outer component and is handled there
      errorCode = ex.code || "fake_general_error_so_we_show_something";

      if (Array.isArray(errorCode)) {
        // Take the first error raised in Users.validate() method.
        errorCode = errorCode[0][0];

        // Focus the invalid input field according to the error raised in Users.validate() method.
        window.requestAnimationFrame(() => {
          //@ts-ignore
          const element: HTMLElement = document.querySelector(`[name=${errorCode}]`);
          if (element) {
            //@ts-ignore
            if (element.select) {
              //@ts-ignore
              element.select();
            }
            element.focus();
          }
        });
      }
    } finally {
      submitting = false;
      showAcceptTermsModal = false;
    }
  }

  function resetError() {
    errorCode = undefined;
  }

  onMount(() => {
    if (fnameInput) {
      fnameInput.focus();
    }
  });

  function showPasswordClicked(evt: MouseEvent) {
    showPassword = true;
  }

  function hidePasswordClicked(evt: MouseEvent) {
    showPassword = false;
  }

  // let hideForm = true;
</script>

<AcceptTermsModal bind:isOpen={showAcceptTermsModal} on:acceptedTerms={doSignup} />

{#if !$context.hideHeaderText}
  <h2 class="mb-4 font-bold text-nxgray-600 text-18">Create a Smore account</h2>
{/if}
<GoogleButton
  mode="signup"
  bind:this={googleButtonElement}
  requireTOSAcceptance={true}
  on:clickWhileTOSRequired={clickedContinueWithGoogle} />
<div class="flex items-center justify-center my-6">
  <hr class="flex-grow" />
  <span class="px-2 bg-white text-13 text-nxgray-300">or</span>
  <hr class="flex-grow" />
</div>
<!-- <div
  on:click={() => (hideForm = false)}
  class:hidden={!hideForm}
  class="w-full p-2 text-center border-2 border-gray-300 rounded-md text-14 clickable hover:bg-gray-100">
  Continue with email and password
</div> -->
<form class="flex flex-col" on:submit|preventDefault|stopPropagation={submitClicked}>
  <div class="flex items-center justify-between">
    <div>
      <label class="mb-1 text-nxgray-400 text-13" for="fname">First name</label>
      <input
        class="w-full text-14"
        class:valid={fnameValid}
        type="text"
        name="fname"
        bind:this={fnameInput}
        bind:value={$context.firstName}
        autocomplete="given-name"
        placeholder=" " />
      <div class="text-red-500 text-13" class:visible={fnameValidationMessage} class:invisible={!fnameValidationMessage}>
        {fnameValidationMessage}
      </div>
    </div>
    <div class="ml-2">
      <label class="mb-1 text-nxgray-400 text-13" for="lname">Last name</label>
      <input
        class="w-full text-14"
        class:valid={lnameValid}
        type="text"
        name="lname"
        bind:value={$context.lastName}
        autocomplete="family-name"
        placeholder=" " />
      <div class="text-red-500 text-13" class:visible={lnameValidationMessage} class:invisible={!lnameValidationMessage}>
        {lnameValidationMessage}
      </div>
    </div>
  </div>

  <label class="mb-1 text-nxgray-400 text-13" for="email">Email address</label>
  <input
    class="text-14"
    class:valid={emailValid}
    class:bg-gray-100={isOrgInvite}
    class:text-nxgray-200={isOrgInvite}
    class:text-nxgray-400={!isOrgInvite}
    class:pointer-events-none={isOrgInvite}
    type="text"
    name="email"
    bind:value={email}
    autocomplete="email"
    readonly={isOrgInvite}
    tabindex={isOrgInvite ? -1 : undefined}
    placeholder="name@email.com" />
  <div class="text-red-500 text-13" class:visible={emailValidationMessage} class:invisible={!emailValidationMessage}>
    {emailValidationMessage}
  </div>

  <div class="flex items-center justify-between">
    <label class="mb-1 text-nxgray-400 text-13" for="password">Password</label>
  </div>
  {#if showPassword}
    <div class="relative">
      <input
        class="w-full text-14"
        class:valid={passwordValid}
        type="text"
        name="password"
        bind:value={password}
        placeholder="Enter your password" />
      <label class="absolute w-4 right-4 top-1/4 text-nxgray-400 clickable" for="password" on:click|stopPropagation={hidePasswordClicked}>
        <span class="pointer-events-none material-icons text-18"> visibility_off </span>
      </label>
    </div>
  {:else}
    <div class="relative w-full">
      <input
        class="w-full pr-10 text-14"
        class:valid={passwordValid}
        type="password"
        name="password"
        bind:value={password}
        placeholder="Enter your password" />
      <label class="absolute w-4 right-4 top-1/4 text-nxgray-400 clickable" for="password" on:click|stopPropagation={showPasswordClicked}>
        <span class="pointer-events-none material-icons text-18"> visibility </span>
      </label>
    </div>
  {/if}
  {#if showReCaptcha && (email || password)}
    <div transition:fade>
      <Recaptcha bind:token={recaptchaToken} bind:this={recaptchaComponent} />
    </div>
  {/if}
  <input
    type="submit"
    on:click|preventDefault|stopPropagation={submitClicked}
    disabled={!canSubmit}
    class="w-full py-3 mt-6 font-bold text-center text-white bg-gray-200 rounded pointer-events-none select-none hover:bg-gray-100 focus:bg-gray-100 text-15"
    class:pointer-events-auto={canSubmit}
    class:bg-orange-600={canSubmit}
    class:clickable={canSubmit}
    class:hover:bg-orange-500={canSubmit}
    class:focus:bg-orange-500={canSubmit}
    value="Create a Smore account" />
</form>

<style>
  input[name="password"] {
    padding-right: 2rem !important;
  }

  input:not([type="submit"]) {
    padding-left: theme("padding.3");
    padding-right: theme("padding.3");
    padding-top: theme("padding.2");
    padding-bottom: theme("padding.2");
    border-radius: theme("borderRadius.DEFAULT");
    border-width: theme("borderWidth.DEFAULT");
    color: theme("textColor.nxgray.600");
    outline-offset: theme("outlineOffset.2");
    outline-width: theme("outlineWidth.2");
    outline-color: theme("outlineColor.transparent");
    outline-style: solid;
  }

  input:not([type="submit"]):not(.valid):not(:placeholder-shown):focus,
  input:not([type="submit"]):not(.valid):not(:placeholder-shown) {
    border-color: theme("borderColor.red.500");
  }

  input:not([type="submit"]):not([readonly]).valid:focus,
  input:not([type="submit"]):not([readonly]).valid {
    border-color: theme("borderColor.green.500");
  }
</style>
