<script lang="ts">
  import { fade } from "svelte/transition";
  import { ServerError } from "@ui/http";
  import Login from "./Login.svelte";
  import Signup from "./Signup.svelte";
  import ForgotPassword from "./ForgotPassword.svelte";
  import ResetPasswordEmailSent from "./ResetPasswordEmailSent.svelte";
  import ResetPassword from "./ResetPassword.svelte";
  import VerifyCodeByEmail from "./VerifyCodeByEmail.svelte";
  import { getAuthenticationContext } from "./context";
  import FireImage from "./images/fire.svg";
  import PartySmorinio from "./images/party-smorinio.svg";
  import { createEventDispatcher } from "svelte";
  import type { AuthenticationMode, CodeVerification } from "./types";
  import ConvertFacebook from "./ConvertFacebook.svelte";
  import CreatePasswordForFacebookEmailSent from "./CreatePasswordForFacebookEmailSent.svelte";
  import CreatePasswordForFacebook from "./CreatePasswordForFacebook.svelte";

  const SHOW_LOGIN_BUTTON_MODES: AuthenticationMode[] = ["login", "signup", "accept-tos"];

  const codeToMessage: { [code: string]: string | ((mode: AuthenticationMode) => string) } = {
    "nouser": "Seems like you put in the wrong email or password.",
    "nosuchuser": "Hmm... sorry we can't seem to find this email.",
    "emailuser": "Please try to login with your email address and password below.",
    "tryemail": "Please try to login with your email address and password below.",
    "tryfacebook": "Seems like you tried logging in with your email,<br/>but you have already registered with a Facebook account.",
    "facebookuser": "Seems like you tried logging in with your email,<br/>but you have already registered with a Facebook account.",
    "invalidtoken": "Seems like we can't automatically log you in, but you can still login manually.",
    "nosignupallowed": "Oops! That info doesn’t match any accounts we have.<br/>Visit smore.com from your desktop to sign up.",
    "googleuser": "In order to login with Gmail, please use the Continue with Google button below.",
    "trygoogle": "In order to login with Gmail, please use the Continue with Google button below.",
    "trysaml": "Try logging in with your SAML account.",
    "badpassword": "Seems like you put in the wrong email or password.",
    "confirm_not_a_robot": "Please confirm you are not a robot.",
    "user exists": "We already have a user with that email.<br/>Try logging in instead.",
    "email": "Your email address is invalid",
    "missing_first_name": "First name can't be blank",
    "missing_last_name": "Last name can't be blank",
    "first_name_too_long": "First name is too long",
    "last_name_too_long": "Last name is too long",
    "invalid_first_name": "Invalid first name",
    "invalid_last_name": "Invalid last name",
    "invalid_verification_code": "Code is invalid",
    "expired_verification_code": "Code has expired. Please try again",
    "google_recaptch_error": "Please make sure you've marked yourself as 'Not a Robot'",
    "invite_email": "Use email address from Invite",
    "password_compromised": (mode) => {
      return mode === "login"
        ? `Please <a href="/reset-your-password" class="underline">reset your password</a> to something more strong and secure. <br/><br/> Your password was identified as being too common and easy to guess.`
        : `Your password is too common.<br/><br/> Please create a unique password with an uppercase letter, a symbol and a number to continue.`;
    }
  };

  export let errorCode: string | undefined = undefined;
  export let email: string = "";
  export let resetPasswordToken: string = "";
  export let resettingFacebookUserEmail: string = "";
  export let resettingFacebookUserToken: string = "";
  export let codeVerification: CodeVerification | undefined = undefined;
  export let hideSmoreLogo: boolean = false;
  export let disableReCaptcha: boolean = false;
  export let norc: boolean = false;

  const context = getAuthenticationContext();
  const dispatcher = createEventDispatcher();

  const urlParams = new URLSearchParams(window.location.search);
  $: hideReCaptcha = norc || urlParams.has("norc");

  let signupStep: number = 1;
  function toggleLoginSignup() {
    let newMode = "login";
    if ($context.mode === "login") {
      newMode = "signup";
    }
    errorCode = undefined;
    dispatcher("modeChanged", newMode);
  }

  function errMsg(code: string) {
    const m = codeToMessage[code] || ServerError.GENERIC_ERROR;
    if (typeof m === "function") {
      return m($context.mode);
    }
    return m;
  }
</script>

<div class="flex items-start justify-center w-full" class:justify-between={signupStep === 1}>
  {#if signupStep === 1}
    {#if !hideSmoreLogo}
      {#if $context.disableSmoreLogoLink}
        <img class="mb-8" src={FireImage} alt="Smore logo" />
      {:else}
        <a href="/app">
          <img class="mb-8" src={FireImage} alt="Smore logo" />
        </a>
      {/if}
    {/if}

    {#if $context.hideSignup && $context.backUrl}
      <a
        href={$context.backUrl}
        class="px-3 py-2 font-bold text-white bg-blue-600 rounded clickable text-14 hover:bg-blue-500 focus:bg-blue-500">« Back</a>
    {:else if !$context.preventSignup && !$context.hideSignup && SHOW_LOGIN_BUTTON_MODES.includes($context.mode)}
      <button
        on:click|preventDefault|stopPropagation={toggleLoginSignup}
        class="px-3 py-2 font-bold text-white bg-blue-600 rounded clickable text-14 hover:bg-blue-500 focus:bg-blue-500"
        >{$context.mode === "login" ? "Create an account" : "Log in"}</button>
    {/if}
  {:else}
    <img class="w-20 transform md:w-auto -rotate-6" src={PartySmorinio} alt="Party Smorinio" />
    <div class="ml-4 text-orange-600">
      <h2 class="font-bold text-orange-600 text-21">Thanks for choosing Smore!</h2>
      <div class="text-15">You’re about to create amazing things.</div>
    </div>
  {/if}
</div>
{#if errorCode}
  <div transition:fade class="px-4 py-2 mb-4 font-bold text-white bg-red-500 rounded">
    {@html errMsg(errorCode)}
  </div>
{/if}
<div>
  {#if $context.mode === "login"}
    <div>
      <Login on:login on:require2FA bind:errorCode {email} on:modeChanged showReCaptcha={!hideReCaptcha} />
    </div>
  {:else if $context.mode === "signup" || $context.mode === "accept-tos"}
    <div>
      <Signup
        on:signup
        bind:errorCode
        {email}
        on:modeChanged
        showReCaptcha={!hideReCaptcha && !disableReCaptcha}
        showTermsAndContinueWithGoogle={$context.mode === "accept-tos"} />
    </div>
  {:else if $context.mode === "forgot-password"}
    <ForgotPassword {email} on:modeChanged />
  {:else if $context.mode === "reset-your-password"}
    <ForgotPassword {email} on:modeChanged compromised={true} />
  {:else if $context.mode === "reset-password-email-sent"}
    <ResetPasswordEmailSent on:modeChanged />
  {:else if $context.mode === "reset-password"}
    <ResetPassword token={resetPasswordToken} />
  {:else if $context.mode === "convert-facebook"}
    <ConvertFacebook on:modeChanged />
  {:else if $context.mode === "create-fb-password-email-sent"}
    <CreatePasswordForFacebookEmailSent on:modeChanged />
  {:else if $context.mode === "create-fb-password"}
    <CreatePasswordForFacebook token={resettingFacebookUserToken} email={resettingFacebookUserEmail} />
  {:else if $context.mode === "login-code" && codeVerification}
    <VerifyCodeByEmail {codeVerification} bind:errorCode on:modeChanged on:login />
  {/if}
</div>
